@import url(https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&display=swap);
/* Font */

/* Color Variables */
:root {
  --primary-color: #1f62f2;
  --footer-bg: #111820;
  --footer-text: #c7c7c7;
  --border: #c7c7c7;
}
.sticky-footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: #fff;
  color: #4e4f50;
  text-align: center;
  box-shadow: 0 10px 70px 0 rgb(0 0 0 / 40%);
  z-index: 3;
}
.sticky-footer a {
  color: #4e4f50;
}
/* =======================================================================================*/
/* =======================================================================================*/
/* =======================================================================================*/

/* =======================================New CSS=============================================*/
* {
  padding: 0;
  margin: 0;
}
/* ======================== General Btn ============================= */
.general-btn {
  border: 0.01rem solid #1f62f2;
  border: 0.01rem solid var(--primary-color);
  border-radius: 0rem;
  background-color: #1f62f2 !important;
  background-color: var(--primary-color) !important;
  transition: 0.5s ease;
  border-radius: 0;
  color: #fff !important;
}
.general-btn:hover {
  color: #1f62f2 !important;
  color: var(--primary-color) !important;
  background-color: #fff !important;
  border: 1px solid #1f62f2;
  border: 1px solid var(--primary-color);
}
/* =============================================================== */
.footer .youtube {
  color: rgb(196, 4, 4) !important;
}
.footer .facebook {
  color: var(--primary);
}
.footer .instagram {
  color: #f77737;
}
.footer .flicker {
  color: red !important;
}
.footer .pinterest {
  color: rgb(163, 22, 22) !important;
}
.how-it-works .block-1 {
  border: 3px solid #1f62f2;
  border: 3px solid var(--primary-color);
  border-radius: 10px;
}
.how-it-works .block-2 {
  padding: 20px;
  border: 3px solid #1f62f2;
  border: 3px solid var(--primary-color);
  border-radius: 10px;
}
.times-cross {
  font-size: 40px !important;
}
.checked .c-1 {
  border-right: 1px solid lightgray;
  border-bottom: 1px solid lightgray;
}
.checked .c-2 {
  border-right: 1px solid lightgray;
  border-bottom: 1px solid lightgray;
}
.checked .c-3 {
  border-bottom: 1px solid lightgray;
}
.checked .c-4 {
  border-right: 1px solid lightgrey;
}
.checked .c-5 {
  border-right: 1px solid lightgrey;
}
.checked .card:hover {
  z-index: 3;
  background-color: #f8f9fa;
  border-radius: 0;
  box-shadow: 0 30px 70px rgb(0 0 0 / 20%) !important;
}

/* =======================================================================================*/

.header-nav .nav-link {
  padding: 12px 15px !important;
}
.header-nav .nav-link:hover {
  background-color: #fff !important;
}
.products .product-btn {
  background-color: #1f62f2 !important;
  background-color: var(--primary-color) !important;
}
/* ======================================= product page =============================================*/
.products .product-cont {
  width: 60%;
}
.products .products-card {
  background: #fff;
  text-align: center;
  padding: 18px;
  margin-bottom: 30px;
  border-radius: 4px;
  box-shadow: 0 1px 10px 0 rgb(0 0 0 / 23%);
  transition: all 0.2s;
  cursor: pointer;
  border: 0;
}
.products .products-card.active {
  background-color: #1871c3;
  color: #fff;
}
.products .products-card h5 {
  font-weight: 300;
}
.products .products-card .products-icon {
  color: #fff;
  background-color: #fff;
  border: 2px solid #1f62f2;
  border: 2px solid var(--primary-color);
  border-radius: 50%;
  padding: 12px;
}
.products .products-card:hover {
  background-color: #1871c3;
  color: #fff;
  box-shadow: 0 5px 15px 0 rgb(0 0 0 / 29%);
  z-index: 3;
}

.products .products-btn {
  display: inline-block;
  padding: 0.75rem 1.25rem;
  border-radius: 10rem;
  color: #fff;
  text-transform: capitalize;
  font-size: 1.1rem;
  font-weight: 600;
  letter-spacing: 0.05rem;
  transition: all 0.3s;
  position: relative;
  overflow: hidden;
  z-index: 1;
}
/* ======================================= Details page =============================================*/
.details .valueplan-card {
  box-shadow: 0 5px 10px 2px rgb(0 0 0 / 16%);
  border-radius: 10px;
}
.details .valueplan-card .v-details {
  border: 3px solid #000;
  border-radius: 6px;
}
.details .valueplan-card .v-details:hover {
  border: 3px solid #1f62f2;
  border: 3px solid var(--primary-color);
  border-radius: 6px;
}
.details .order p {
  font-size: 20px;
}
.details .order {
  border-radius: 10px;
  background-color: #f0f0f0;
  border: 0;
}
.details .faq-card {
  border-radius: 10px;
}
/* ======================================= Result page =============================================*/
.resultpage button {
  background-image: linear-gradient(to right, #1f62f2, #1f62f2);
}
/* hero */
.heroCard {
  width: 90%;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px,
    rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
  background-color: #1871c3 !important;
}
.hero-font {
  font-size: 19px;
}
.hero-font span {
  font-weight: bolder;
  text-decoration: underline;
}
.card-font a {
  color: #fff;
  font-size: 14px;
}
.card-font p {
  color: #fff;
  font-size: 14px;
}
.card-font a {
  text-decoration: underline;
}
.btn-custom-primary {
  box-shadow: 0 3px 0 #073db1;
  font-size: 16px !important;
  padding: 0.5rem 2rem;
  font-weight: 700;
  text-align: center;
  cursor: pointer;
  outline: none;
  color: #fff;
  background-color: #1f62f2;
  background-color: var(--primary-color);
  border: none !important;
  border-radius: 3px;
  text-transform: uppercase;
}
.btn-custom-primary:active {
  box-shadow: none !important;
  -webkit-transform: translateY(2px) !important;
          transform: translateY(2px) !important;
}
.btn-custom-primary:hover {
  color: #fff !important;
}
/* Button */
.btn-custom-success {
  box-shadow: 0 3px 0 #058013fd;
  font-size: 16px !important;
  padding: 0.5rem 2rem;
  font-weight: 700;
  text-align: center;
  cursor: pointer;
  outline: none;
  color: #fff;
  background-color: #0dcf47;
  border: none !important;
  border-radius: 3px;
  text-transform: uppercase;
}
.btn-custom-success:active {
  box-shadow: none !important;
  -webkit-transform: translateY(2px) !important;
          transform: translateY(2px) !important;
}

/* Damage */
.damage .card-title {
  text-decoration: underline;
}
/* Damage */

.never h2 span {
  color: rgb(238, 71, 11);
}
.never p {
  color: rgb(73, 66, 66);
}
.never ul li {
  list-style: none;
  padding: 5px;
  font-size: 14px;
}

.never {
  font-size: 16px;
}
/* .never button{
  background-color: rgb(70, 73, 245);
  color:#fff;
  font-size:15px;
  padding: 10px 20px;
} */
.never img {
  box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 28px 0px,
    rgba(126, 126, 126, 0.719) 0px 2px 4px 0px,
    rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset;
}

/* Check */
.check h5 {
  color: rgb(70, 73, 245);
}
.check .card-1,
.card-2,
.card-3 {
  border-bottom: 1px solid #e9e5e5;
}
.check .card-2,
.card-5 {
  border-right: 1px solid #e9e5e5;
  border-left: 1px solid #e9e5e5;
}
.check img {
  width: 70px;
  height: 70px;
}
.check p {
  color: #998e8e;
}
/* Never */
.never ul li {
  color: orangere;
}

/* Stat */

.stat img {
  width: 190px;
  height: 130px;
}
.stat span svg {
  padding: 7px;
}
.stat svg {
  background: rgb(0, 182, 123);
  color: white;
  font-size: 40px;
}
.stat p {
  color: #6b6666;
}
.statistics span svg {
  background-color: white;
  padding: 0px;
}
.statistics svg {
  color: gold;
}
.google img {
  width: 110px;
  height: 80px;
}

/* Sample Sheet*/

.sampleSheet img {
  width: 300px;
  height: 300px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 28px 0px,
    rgba(160, 160, 160, 0.719) 0px 2px 4px 0px,
    rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset;
}

/* Car Brands */

.carBrands {
  border-top: 1px solid #000;
}

/* Social Links */

.social {
  border: 1px solid #e9e5e5;
  padding: 15px;
}
.social p,
.verify p {
  font-size: 30px;
  font-weight: 400;
}
.social span {
  margin: 0;
  padding: 0;
  font-size: 20px;
  font-weight: 400;
}
.social a {
  color: #000;
}
.verify {
  border: 1px solid #e9e5e5;
  padding: 15px;
}
.verify input {
  border: 1px solid rgb(24, 23, 23);
  border-radius: 3px;
}
.verify button {
  border: 1px solid rgb(70, 73, 245);
  border-radius: 3px;
  color: rgb(70, 73, 245);
  background-color: #fff;
}
.verify .subscribe {
  font-size: 14px;
  color: #6b6666;
}
.verify .subscribe a {
  text-decoration: underline;
  color: rgb(70, 73, 245);
  font-weight: 500;
}

/* Footer */

.footer {
  border-top: 2px solid #e9e5e5;
}
.footer ul li {
  padding: 5px;
  list-style: none;
}
.footer ul li a {
  font-size: 13px;
  color: #6b6666;
  text-decoration: none;
}
.footer address {
  font-size: 13px;
  color: #6b6666;
}

/* Videos */

.videos iframe {
  border-radius: 8px;
}

/********************** Media Queries Mobile *************************/
@media screen and (max-width: 480px) {
  /* Hero */
  .social p,
  .verify p {
    font-size: 0px;
    font-weight: 400;
  }
  .checked .c-1,
  .c-2,
  .c-3,
  .c-4,
  .c-5,
  .c-6 {
    border-bottom: 1px solid lightgrey !important;
    border-right: none !important;
    border-left: none !important;
  }
  .heroCard {
    width: 100%;
  }
  /* Check */
  .check .card-1,
  .card-2,
  .card-3,
  .card-4,
  .card-5,
  .card-6 {
    border: 1px solid #e9e5e5;
  }
  .check h1 {
    font-size: 25px;
  }
  .check h5 {
    font-size: 15px;
  }
}

/***********************************************/

@media screen and (min-width: 768px) {
  .sticky-footer {
    display: none;
  }
}

/********************* Media Queries Tablet **************************/
@media screen and (max-width: 768px) {
  .heroCard {
    width: 100%;
  }
}
/*********************** New Css End ************************/
/***********************************************/
/***********************************************/
/***********************************************/

/* Body & HTML Tags */
* {
  font-family: "Lato", sans-serif;
}
a {
  color: #1f62f2;
  color: var(--primary-color);
}
#page img {
  width: 100% !important;
  height: auto !important;
}

/* Call Button */
.call-btn a {
  border: 2px solid #1f62f2;
  border: 2px solid var(--primary-color);
  padding: 2px;
}
@media (max-width: 480px) {
  .hidden-element {
    display: none;
  }
  .video {
    height: 300px !important;
  }
}
.call-btn a span,
.call-btn a span:hover {
  background-color: #1f62f2;
  background-color: var(--primary-color);
}

/* Payment Button */
.paypal-button-number-1 {
  display: none !important;
  visibility: hidden !important;
}

.PhoneInputInput {
  border-color: transparent !important;
}

/* Breadcrumb */
.breadcrumb {
  background-color: #fff !important;
  padding: 0rem !important;
  border: none !important;
}
.breadcrumb .breadcrumb-item {
  font-size: 0.85rem;
}
.breadcrumb .breadcrumb-item a {
  color: #000;
}
.breadcrumb .breadcrumb-item .active {
  opacity: 0.75;
}
/* !Breadcrumb */

/* Login Buttons */
.btnFacebook {
  width: 100%;
  border-radius: 4px;
  background: #3b5998;
  color: white;
  border: 0px transparent;
  text-align: center;
  margin: 10px;
  padding: 10px;
  display: inline-block;
}
.btnGoogle {
  margin: 10px;
  padding: 10px;
  width: 100%;
  border-radius: 4px;
  background: #db3236;
  color: white;
  border: 0px transparent;
  text-align: center;
}
.btnFacebook:hover {
  background: #3b5998;
  opacity: 0.6;
}
.btnGoogle:hover {
  background: #db3236;
  opacity: 0.6;
}
@media only screen and (max-width: 480px) {
  .btnFacebook,
  .btnGoogle {
    width: 100%;
    margin: 10px 0px;
  }
}
/* !Login Buttons */

/* Payments */
.StripeElement {
  margin: 15px auto;
  padding: 10px 12px;
  color: #32325d;
  background-color: white;
  border: 1px solid transparent;
  border-radius: 4px;
}
.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}
.product {
  width: 100%;
  max-width: 450px;
  margin: auto;
  box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.4);
  border-radius: 10px;
  overflow: hidden;
}
.btn-pay {
  display: block;
  width: 100%;
  border: none;
  background: linear-gradient(
    135deg,
    rgb(49, 0, 62) 0%,
    rgb(195, 40, 110) 100%
  );
  color: #fff;
  padding: 10px;
  font-size: 18px;
  cursor: pointer;
}
#stripe-btn {
  width: 100%;
  height: auto;
  box-shadow: 0.25rem 0.25rem #839bd7;
}
/* !Payments */

/* Sidebar */
#sidebar .icon-bar {
  position: fixed;
  z-index: 1;
  top: 50%;
  right: 0%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
@media (min-width: 481px) {
  #sidebar .icon-bar a {
    display: block;
    text-align: center;
    padding: 5px 17.5px;
    transition: all 0.3s ease;
    margin: 1px 0px;
    color: white;
    font-size: 35px;
  }
}
@media (max-width: 480px) {
  #sidebar .icon-bar a {
    display: block;
    text-align: center;
    padding: 5px 10px;
    transition: all 0.3s ease;
    margin: 1px 0px;
    color: white;
    font-size: 30px;
  }
}
#sidebar .icon-bar a:hover {
  background-color: #1f62f2;
  background-color: var(--primary-color);
}
#sidebar .icon-bar .whatsapp {
  background-color: #25d366;
}
#sidebar .icon-bar .facebook {
  background-color: #3b5998;
}
#sidebar .icon-bar .linkedin {
  background-color: #0e76a8;
}
#sidebar .icon-bar .instagram {
  background-color: #3f729b;
}
#sidebar .bg {
  background-color: #333;
  color: white;
}
@media (max-width: 480px) {
  #sidebar {
    visibility: hidden;
    display: none;
  }
}
/* !Sidebar */

/* Ad Banner */
.adBanner {
  box-shadow: 0.25rem 0.25rem #ccc;
}
/* !Ad Banner */

/* Toast */
.toast {
  position: fixed;
  top: 15px;
  right: 15px;
  z-index: 1000;
}
.toast img {
  width: 5rem;
}
.toast-body,
.toast-header {
  color: #333 !important;
}
/* !Toast */

/* Header */
.navbar .navbar-brand img {
  height: 50px;
}
.navbar .nav-item {
  margin: 0rem 1rem;
}
.navbar .login {
  color: #1f62f2 !important;
  color: var(--primary-color) !important;
}
.navbar .sign-up-btn {
  border: 0.01rem solid #1f62f2;
  border: 0.01rem solid var(--primary-color);
  border-radius: 0rem;
  background-color: #1f62f2;
  background-color: var(--primary-color);
  transition: 0.5s ease;
}
.navbar .sign-up-btn:hover {
  color: #1f62f2;
  color: var(--primary-color);
  background-color: #fff;
}
/* Header-dropdown */
.navbar .dropdown .dropdown-menu {
  border: none;
  border-radius: 0rem;
  border-bottom: 0.25rem solid #1f62f2;
  border-bottom: 0.25rem solid var(--primary-color);
}
.navbar .dropdown .dropdown-menu .dropdown-item {
  font-size: 0.8rem;
  transition: 0.25s ease;
}
.navbar .dropdown .dropdown-menu .dropdown-item:hover {
  color: #fff;
  background-color: #1f62f2;
  background-color: var(--primary-color);
}
.dropdown:hover .dropdown-menu {
  display: block;
}
/* Header - Responsive */
@media (max-width: 500px) {
  .navbar #header-navbar-nav {
    padding-top: 1rem;
  }
}
/* !Header */

/* Footer */
footer {
  font-size: 1rem;
  background-color: #111820;
  background-color: var(--footer-bg);
  color: #c7c7c7;
  color: var(--footer-text);
  padding: 2rem;
  padding-bottom: 1.5rem;
}
footer .logo {
  margin-top: 0.7rem;
}
footer hr {
  border-color: #c7c7c7;
  border-color: var(--footer-text);
  opacity: 0.5;
}
footer a {
  color: #c7c7c7;
  color: var(--footer-text);
  padding: 0rem 0.75rem;
}
footer a:hover {
  color: #1f62f2;
  color: var(--primary-color);
  text-decoration: none;
}
footer .right {
  float: right;
}
footer .social a {
  font-size: 1.25rem;
}
footer .copyright {
  font-size: 0.75rem;
}
footer .copyright .copyright-left {
  float: left;
}
footer .copyright .copyright-right {
  float: right;
  margin-top: 0rem !important;
}
footer .copyright img {
  width: 25px;
}
@media (max-width: 500px) {
  footer .right {
    margin-top: 1.5rem;
    float: none;
  }
  footer .logo {
    width: 200px;
  }
  footer .copyright {
    text-align: center;
  }
  footer .copyright .copyright-left {
    float: none;
  }
  footer .copyright .copyright-right {
    float: none;
  }
}
/* !Footer */

/* SignIn-SignUp Pages */
.login h1 {
  font-size: 2.5rem;
  margin-bottom: 0rem;
  font-weight: 600;
}
.login .col-border-right {
  border-right: 0.02rem solid #c7c7c7;
  border-right: 0.02rem solid var(--border);
}
.login .form .form-control {
  border: none;
  border-radius: 0;
  border-bottom: 0.01rem solid #c7c7c7;
  border-bottom: 0.01rem solid var(--border);
  box-shadow: none;
  margin-bottom: 2rem;
  transition: 0.5s ease;
}
.login .form .form-control:focus {
  border-bottom-width: 0.1rem;
  font-size: 1.1rem;
}
.login .form {
  padding: 2rem 0rem;
}
.login .form p {
  font-size: 0.8rem;
}
.login .form .btn {
  background-color: #1f62f2;
  background-color: var(--primary-color);
  border-radius: 0rem;
}
@media (max-width: 500px) {
  .login .col-border-right {
    border-right: none;
  }
}
/* !SignIn-SignUp Pages */

/* --------------- Website Pages --------------- */

/* Home Page */
.home .row1 h1 {
  font-weight: bolder;
  font-size: 2.25rem;
}
.home .row1 h6 {
  font-weight: bolder;
  font-size: 1.3rem;
}
.home .row1 .points .row .col-1 {
  padding-right: 0.25rem;
  padding-bottom: 0.2rem;
  font-weight: bolder;
}
.home .row1 .points .row .col-11 {
  font-size: 0.9rem;
}
.home .row1 .btn {
  border-radius: 5px !important;
  background-color: #fff !important;
  color: #1f62f2;
  color: var(--primary-color);
  font-weight: bold;
  transition: 0.5s ease-in-out;
}
.home .row1 .btn:hover {
  background-color: #1f62f2 !important;
  background-color: var(--primary-color) !important;
  color: #fff !important;
  border: 1px solid #fff !important;
}
.home .row1 {
  background-color: #1c1c1c;
  color: #fff;
}
.home .stat {
  background-color: #1871c3;
  color: #ffffff;
}
.home .stat h3 {
  text-align: center;
  font-weight: 600;
}
.home .stat p {
  text-align: center;
  font-weight: 300;
}
.home .row1 .PhoneInputCountryIconImg {
  color: #000 !important;
}
@media (max-width: 480px) {
  .home .row1 {
    padding-top: 1rem !important;
  }
  .home .row1 input {
    width: 100% !important;
  }
  .home .row1 .phone {
    width: 87% !important;
    box-shadow: none;
  }
  .home .row1 .img-fluid {
    float: right;
    margin-bottom: 150px;
  }
  .home .row1 .col-md-5 {
    order: 2;
  }
}
@media (min-width: 481px) {
  .home .row1 {
    padding: 0rem 3rem;
  }
  .home .row1 input {
    width: 100%;
    box-shadow: none;
  }
  .popup .phone {
    border: 1px solid #d3d3d3;
    border-radius: 5px;
    box-shadow: none;
  }
  .home .row1 .phone {
    width: 100%;
    box-shadow: none;
  }
  .home .row1 .img-fluid {
    height: 30rem;
  }
}
.home .row1 .form-label {
  font-weight: 700;
}
.home .row1 form input::-webkit-input-placeholder {
  text-transform: none !important;
  font-size: 0.95rem;
}
.home .row1 form input::placeholder {
  text-transform: none !important;
  font-size: 0.95rem;
}
.home .row1 form .btn {
  margin-top: 0.25rem;
  border-radius: 0;
  background-color: #1f62f2;
  background-color: var(--primary-color);
  transition: 0.5s ease;
  width: 30%;
}
.home .row1 form .btn:hover {
  background-color: #fff;
  color: #1f62f2;
  color: var(--primary-color);
}
/* Modal */
.report h5 {
  color: #1f62f2 !important;
  color: var(--primary-color) !important;
  font-size: 1.5rem;
}
.report h6 {
  font-size: 1.5rem;
}
.report .divider {
  width: 80%;
  border-color: #1f62f2 !important;
  border-width: 3px;
  border-radius: 5px;
}
.report #pay-btn {
  width: 40%;
  margin-bottom: 30px;
}
.home .row2 .icon {
  cursor: pointer;
}
.home .row2 .icon img {
  height: 280px;
}
.home .row2 .icon svg {
  margin-right: 10px;
}
.home .row2 .col-md-3 {
  transition: 0.3s ease-in-out;
}
.home .row2 .col-md-3:hover {
  /* background-color: #C8C9C7; */
}
@media (min-width: 500px) {
  .home .row2 .col-md-3 {
    padding: 25px 30px;
    border-right: 1px solid rgb(222, 226, 230);
  }
  .home .row2 .col-md-3:last-child {
    border-right: none;
  }
}
.home .row2 .icon h6 {
  text-transform: uppercase;
  text-decoration: underline;
  font-size: 1rem;
  font-weight: 600;
  transition: 0.3s ease-in-out;
}
@media (max-width: 499px) {
  .home .row2 .col-md-3 {
    padding: 20px 20px;
    border-top: 1px solid rgb(222, 226, 230);
  }
  .home .row2 .col-md-3:first-child {
    border-top: none;
  }
}
.home .row2 .icon h6:hover {
  color: #000 !important;
  text-decoration: underline;
}
.home .heading2 h2 {
  font-weight: 600;
}
.home .row2 p {
  margin-top: 20px;
  font-size: 0.9rem;
  opacity: 0.75;
  transition: 0.3s ease-in-out;
}
.home .row2 p:hover {
  opacity: 1;
  color: #000;
}
.home .row2 a {
  font-weight: 600;
  color: #1f62f2;
  color: var(--primary-color);
}
.home .row2 .fa-arrow-right,
.home .row4 .fa-arrow-right {
  margin-left: 0.35rem;
  color: #1f62f2;
  color: var(--primary-color);
  font-size: 0.75rem;
}
.home .row3 h2 {
  font-size: 2.5rem;
  font-weight: 600;
}
.home .row3 h2 span {
  color: #1f62f2;
  color: var(--primary-color);
}
.home .row3 p {
  font-size: 1rem;
  margin: 1rem 0rem;
}
.home .row3 a {
  border: 0.01rem solid #1f62f2;
  border: 0.01rem solid var(--primary-color);
  border-radius: 0;
  color: #fff;
  background-color: #1f62f2;
  background-color: var(--primary-color);
  transition: 0.5s ease;
}
.home .row3 a:hover {
  background-color: #fff;
  color: #1f62f2;
  color: var(--primary-color);
}
.home .row4 {
  margin: 2rem 0rem;
}
.home .row4 h2 {
  font-weight: 600;
}
.home .row4 .text-heading {
  transition: 0.25s ease-in-out;
  font-size: 1.2rem !important;
  cursor: pointer;
  font-weight: 700;
}
.home .row4 .text-heading:hover {
  color: #1f62f2;
  color: var(--primary-color);
}
.home .row4 a {
  font-weight: 600;
  color: var(-primary-color);
}
.home .row4 img {
  margin: 0.5rem 0rem;
}
.home .row4 .row .col-sm-3 {
  margin: 0.75rem 0rem;
}
.home .row4 .text-center {
  font-size: 1rem;
  font-weight: 600;
}
.home .row5 h2 {
  font-weight: 600;
}
.home .row5 h5 {
  font-weight: 600;
  transition: 0.5s ease-in-out;
}
.home .row5 h5:hover {
  color: #1f62f2;
  color: var(--primary-color);
}
.home .row5 span {
  color: var(--primary);
}
.home .row5 .img {
  width: 100%;
  height: 200px;
}
.home .row6 h2 {
  font-weight: 600;
}
/* !Home Page */

/* Contact Page */
.contact p {
  font-size: 0.9rem;
}
.contact .btn {
  border-radius: 0rem;
  background-color: #1f62f2;
  background-color: var(--primary-color);
  transition: 0.5s ease;
}
.contact .form-control {
  border-radius: 0rem;
  box-shadow: none;
}
.contact .btn:hover {
  background-color: #fff;
  color: #1f62f2;
  color: var(--primary-color);
}
.contact .card {
  border-radius: 1rem;
}
.contact .card p {
  font-size: 1rem;
}
.contact .card .social a {
  font-size: 2rem;
  margin: 0rem 1rem;
}
/* !Contact Page */

/* Faqs Page */
.faqs .card {
  border: none;
}
.faqs .card .card-header {
  background-color: inherit !important;
  font-weight: 600;
  font-size: 1.1rem;
  margin: 0.5rem 0rem;
  transition: 0.5s ease;
  cursor: pointer;
}
.faqs .card .card-header:hover {
  color: #1f62f2;
  color: var(--primary-color);
}
.faqs .card .card-header .fa-plus {
  margin-right: 0.75rem;
}
/* !Faqs Page */

/* About Us Page */
.about h4 {
  text-transform: uppercase;
  opacity: 0.75;
}
@media (max-width: 500px) {
  .about p {
    margin-top: 2.5rem;
  }
}
/* !About Us Page */

/* Advertise with Us Page */
@media (max-width: 500px) {
  .advertise-with-us img {
    margin-top: 1rem;
  }
}
/* !Advertise with Us Page */

/* Comment Policy Page */
.comment-policy h4 {
  opacity: 0.9;
}
@media (max-width: 500px) {
  .comment-policy img {
    margin-top: 1rem;
  }
}
/* !Comment Policy Page */

/* DMCA Page */
@media (max-width: 500px) {
  .dmca img {
    margin-top: 1rem;
  }
}
/* !DMCA Page */

/* Feedback Page */
@media (max-width: 500px) {
  .feedback img {
    margin-top: 1rem;
  }
}
/* !Feedback Page */

/* How it works Page */
.how-it-works h4 {
  margin-bottom: 0.75rem;
}
@media (max-width: 500px) {
  .how-it-works img {
    margin-top: 1rem;
  }
}
/* !How it works Page */

/* How to read Page */
.how-to-read h3 {
  margin: 1rem 0rem;
}
@media (max-width: 500px) {
  .how-to-read img {
    margin: 1rem 0rem;
  }
}
/* !How to read Page */

/* Our Authors Page */
@media (max-width: 500px) {
  .our-authors img {
    margin: 1rem 0rem;
  }
}
/* !Our Authors Page */

/* Refund Policy Page */
.refund-policy .btn {
  border-radius: 0rem;
  background-color: #1f62f2;
  background-color: var(--primary-color);
  transition: 0.5s ease;
}
.refund-policy .form-control {
  border-radius: 0rem;
  box-shadow: none;
}
.refund-policy .btn:hover {
  background-color: #fff;
  color: #1f62f2;
  color: var(--primary-color);
}
/* !Refund Policy Page */

/* Packages Page */
.packages .demo {
  padding: 50px 0;
}
.packages .heading-title {
  margin-bottom: 50px;
}
.packages .pricingTable {
  border: 1px solid #dbdbdb;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.14);
  margin: 0 -15px;
  text-align: center;
  transition: all 0.4s ease-in-out 0s;
}
.packages .pricingTable:hover {
  border: 2px solid #1f62f2;
  border: 2px solid var(--primary-color);
  margin-top: -30px;
}

.packages .pricingTable .pricingTable-header {
  padding: 30px 10px;
}
.packages .pricingTable .heading {
  display: block;
  color: #000;
  font-weight: 900;
  text-transform: uppercase;
  font-size: 21px;
}
.packages .pricingTable .pricing-plans {
  padding-bottom: 25px;
  border-bottom: 1px solid #d0d0d0;
  color: #000;
  font-weight: 900;
}
.packages .pricingTable .price-value {
  color: #474747;
  display: block;
  font-size: 25px;
  font-weight: 800;
  line-height: 35px;
  padding: 0 10px;
}
.packages .pricingTable .price-value span {
  font-size: 50px;
  color: #1f62f2;
  color: var(--primary-color);
}
.packages .pricingTable .subtitle {
  color: #82919f;
  display: block;
  font-size: 15px;
  margin-top: 15px;
  font-weight: 100;
}
.packages .pricingTable .pricingContent ul {
  padding: 0;
  list-style: none;
  margin-bottom: 0;
}
.packages .pricingTable .pricingContent ul li {
  padding: 20px 0;
}
.packages .pricingTable .pricingContent ul li:nth-child(odd) {
  background-color: #fff;
}
.packages .pricingTable .pricingContent ul li:last-child {
  border-bottom: 1px solid #dbdbdb;
}
.packages .pricingTable .pricingTable-sign-up {
  padding: 25px 0;
}
.packages .pricingTable .btn-block {
  width: 50%;
  margin: 0 auto;
  background: #1f62f2;
  background: var(--primary-color);
  border: 1px solid transparent;
  padding: 10px 5px;
  color: #fff;
  text-transform: capitalize;
  border-radius: 0;
  transition: 0.3s ease;
}
.packages .pricingTable:hover .btn-block {
  background: #fff;
  color: #1f62f2;
  color: var(--primary-color);
  border: 1px solid #1f62f2;
  border: 1px solid var(--primary-color);
}
@media screen and (max-width: 990px) {
  .packages .pricingTable {
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 767px) {
  .packages .pricingTable {
    margin: 0 0 30px 0;
  }
}
/* !Packages Page */

/* AuctionSheet Page */
.auctionsheet .grade-heading {
  border: 0.075rem dotted #000;
  padding: 1rem;
}
.auctionsheet .grade-heading {
  font-size: 1rem;
  font-weight: 600;
}
.auctionsheet .auction-details .col-sm-6 {
  margin: 0.5rem 0rem;
}
.auctionsheet .auction-details .col-sm-6 .text-center {
  margin-left: 0.5rem;
  font-weight: 600;
}
.auctionsheet .report {
  width: 100%;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
@media (min-width: 800px) {
  .auctionsheet .report {
    border: 1rem solid #0c54a3;
    border-top: none;
  }
}
@media (max-width: 799px) AND (min-width: 480px) {
  .auctionsheet .report {
    border: 0.6rem solid #0c54a3;
    border-top: none;
  }
  .products .product-cont {
    width: 100%;
  }
  .header-img {
    height: 200px;
    width: 400px;
  }
}
@media (max-width: 1024px) {
  .header-img {
    width: 500px;
    height: 200px;
  }
  .hidden-btn {
    display: none !important;
  }
}
@media (max-width: 479px) {
  .reviews .trust-pilot {
    height: 40px;
    width: 60px;
  }
  .reviews .google-reviews {
    width: 50px;
    height: 40px;
  }
  .products .product-cont {
    width: 100%;
  }
  .header-img {
    display: none;
  }
  .hero-heading {
    display: none;
  }
  .hero-img .background-img img {
    width: 100%;
    /* height: 200px!important; */
  }
  .home .row2 .icon img {
    width: 100%;
    object-fit: cover;
  }
  .auctionsheet .report {
    border: 0.85rem solid #0c54a3;
    border-top: none;
  }
}
.auctionsheet .report .chassis-no {
  font-size: 1.25rem;
}
.auctionsheet .row .col-md-6 img {
  box-shadow: 0.25rem 0.25rem #ccc;
}
#btn-translate {
  width: 60%;
  border: 2px solid var(--primary) !important;
  transition: 0.25s ease-in-out;
}
#btn-translate:hover {
  background-color: #fff !important;
  border: 2px solid var(--primary) !important;
  color: var(--primary) !important;
}
.auctionsheet .report table tbody {
  font-size: 0.9rem;
}
.auctionsheet .report table tbody td {
  padding: 4.1px;
  font-weight: 600;
}

/* !AuctionSheet Page */

/* --------------- !Website Pages --------------- */
@media (max-width:480px){
  iframe{
    bottom: 80px!important;
  }
}
